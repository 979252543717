// Dependencies
import React, { useRef } from "react"
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Components
import Layout from "../components/Layout"
import Markdown from "../components/Markdown"
import RichText from "../components/RichText"

const ContactPage = ({ data, pageContext }) => {

	const { title, telephone, email, locations, notes } = data.allContentfulContact.nodes[0];

	return (
		<Layout title="Contact" headerTitle={"Contact"} hideFooter={true}>
			<div className="outerx2 guttersx2 has-links">
				<h1>{title}</h1>
				<h1>{telephone}</h1>
				<h1><a href={`mailto:${email}`} className="link" target="_blank">{email}</a></h1>
			</div>
			<div className="outerx2 guttersx2 grid-3 p3 has-links">
				{locations.map((location, index) => (
					<div className="mb" key={`location-${index}`}>
						<h5>{location.place}</h5>
						<Markdown className="no-m" field={location.address} />
						<a href={location.mapUrl} target="_blank" rel="noopener noreferrer">{`> Map`}</a>
					</div>
				))}
			</div>
			<div className="guttersx2 outerx2 has-links footer-notes">
				<RichText content={notes} />
			</div>
  	</Layout>
	)
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactPage

export const ContactPagePageQuery = graphql`
  query ContactPageQuery {
    allContentfulContact {
			nodes {
				title
				telephone
				email
				locations {
					place
					address {
						childMarkdownRemark {
							html
						}
					}
					mapUrl
				}
				notes {
					raw
					references {
						... on ContentfulAsset {
							contentful_id
							__typename
							id
							title
							file {
								url
							}
						}
					}
				}
			}
		}
  }
`
